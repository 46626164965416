import Vue from 'vue'

const components = [
    'StyleButton',
    'Vrange',
    'VText',
    'CtrlVButton',
    'VButton',
    'Vmatrix',
    'VButtons',
    'VSelect',
    'VFastButton',
    'VFastButtons',
    'VIframe',
    'Vplayer',
    'VWebViewButton',
]

components.forEach(key => {
    Vue.component(key, () => import(`@/custom-component/${key}/Component`))
    Vue.component(key + 'Attr', () => import(`@/custom-component/${key}/Attr`))
})
