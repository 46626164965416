import Vue from 'vue'
import Vuex from 'vuex'
import animation from './animation'
import compose from './compose'
import contextmenu from './contextmenu'
import copy from './copy'
import event from './event'
import layer from './layer'
import snapshot from './snapshot'
import lock from './lock'

Vue.use(Vuex)

const data = {
    state: {
        ...animation.state,
        ...compose.state,
        ...contextmenu.state,
        ...copy.state,
        ...event.state,
        ...layer.state,
        ...snapshot.state,
        ...lock.state,
        pageData: [], // 页面数据
        copyData: [],
        copyComponentData: [],
        copyCanvasStyleData: { // 页面全局数据
            width: 800,
            height: 440,
            // scale: 100,
            // color: '#000',
            // opacity: 1,
            // background: '#f1f4fa',
            // fontSize: 14,
            backgroundImg: '',
            // bgImgWidth:100,
            // bgImgHeight:100
        },
        activePage: 0,
        editMode: 'edit', // 编辑器模式 edit preview
        canvasStyleData: { // 页面全局数据
            width: 800,
            height: 440,
            scale: 100,
            color: '#000',
            opacity: 1,
            background: '#f1f4fa',
            fontSize: 14,
            backgroundImg: '',
            backgroundImgId: '999',
            bgImgWidth: 100,
            bgImgHeight: 100,
        },
        isInEdiotr: false, // 是否在编辑器中，用于判断复制、粘贴组件时是否生效，如果在编辑器外，则无视这些操作
        componentData: [], // 画布组件数据
        curComponent: null,
        curComponentIndex: null,
        // 点击画布时是否点中组件，主要用于取消选中组件用。
        // 如果没点中组件，并且在画布空白处弹起鼠标，则取消当前组件的选中状态
        isClickComponent: false,
        rangeValueList: {
        },
        // 全局状态栏
        setupData: {
            direction: '自由',
            stateBar: '隐藏',
        },
        // 模型数据
        modelDataArr: new Array(),
        // 选中组的组件
        groupComponentArr: [],
        // 选中组的位置
        groupStyle: {},
        matrixData: {
            activeId: null,
            groupId: null,
            data: [],
        },
        runData:[]
    },
    mutations: {
        ...animation.mutations,
        ...compose.mutations,
        ...contextmenu.mutations,
        ...copy.mutations,
        ...event.mutations,
        ...layer.mutations,
        ...snapshot.mutations,
        ...lock.mutations,

        setRunData(state,data){
            Vue.set(state.runData, data.key, data.value);
        },

        resettingMatrixData(state) {
            state.matrixData = {}
        },

        clearGroupComponentArr(state) {
            state.groupComponentArr = []
            state.groupStyle = {}
        },

        addGroupComponentArr(state, value) {
            let length = state.groupComponentArr.length

            state.groupComponentArr[length] = value
        },

        setGroupStyle(state, value) {
            state.groupStyle = value
        },

        setCopyComponentData(state, value) {
            state.copyComponentData = value
        },

        deleteModelData(state, value) {
            state.modelDataArr.splice(value, 1)
        },

        setModelDataArr(state, value) {
            state.modelDataArr.push(value)
        },

        setModelData(state, value) {
            state.modelDataArr = value
        },

        setSetupData(state, value) {
            state.setupData = value
        },

        aceSetCanvasData(state, value) {
            state.canvasStyleData = value
        },

        setActivePage(state, value) {
            state.activePage = value
        },

        setPageData(state, value) {
            state.pageData = value
        },

        setCopyData(state, value) {
            state.copyData = value
        },

        setCopyCanvasStyleData(state, value) {
            state.copyCanvasStyleData = value
        },

        aceSetcurComponent(state, value) {
            for (let i = 0; i < state.componentData.length; i++) {
                if (state.componentData[i].id === value.id) {
                    state.componentData.splice(i, 1)
                }
            }
            state.componentData.push(value)
            state.curComponent = value
        },

        setComponentData(state, value) {
            state.componentData = value
            state.curComponent = value
        },

        setClickComponentStatus(state, status) {
            state.isClickComponent = status
        },

        setEditMode(state, mode) {
            state.editMode = mode
        },

        setInEditorStatus(state, status) {
            state.isInEdiotr = status
        },

        setCanvasStyle(state, style) {
            state.canvasStyleData = style
        },

        setCurComponent(state, { component, index }) {
            state.curComponent = component
            state.curComponentIndex = index
        },

        setCurComponentV(state, value) {
            state.curComponent = value
        },

        setShapeStyle({ curComponent }, { top, left, width, height, rotate }) {
            if (top) curComponent.style.top = Math.round(top)
            if (left) curComponent.style.left = Math.round(left)
            if (width) curComponent.style.width = Math.round(width)
            if (height) curComponent.style.height = Math.round(height)
            if (rotate) curComponent.style.rotate = Math.round(rotate)
        },

        setShapeSingleStyle({ curComponent }, { key, value }) {
            curComponent.style[key] = value
        },

        setComponentData(state, componentData = []) {
            Vue.set(state, 'componentData', componentData)
        },

        addComponent(state, { component, index }) {
            if (index !== undefined) {
                state.componentData.splice(index, 0, component)
            } else {
                state.componentData.push(component)
            }
        },

        deleteComponent(state, index) {
            if (index === undefined) {
                index = state.curComponentIndex
            }

            if (index == state.curComponentIndex) {
                state.curComponentIndex = null
                state.curComponent = null
            }

            if (/\d/.test(index)) {
                state.componentData.splice(index, 1)
            }
        },
    },
}

export default new Vuex.Store(data)
