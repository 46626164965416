import { initModelData } from '@/utils/initModel'

// 公共样式
export const commonStyle = {
    rotate: 0,
    opacity: 1,
}

export const commonAttr = {
    // animations: [],
    // events: {},
    // groupStyle: {}, // 当一个组件成为 Group 的子组件时使用
    isLock: false, // 是否锁定组件
    collapseName: 'style', // 编辑组件时记录当前使用的是哪个折叠面板，再次回来时恢复上次打开的折叠面板，优化用户体验
    // linkage: {
    //     duration: 0, // 过渡持续时间
    //     data: [ // 组件联动
    //         {
    //             id: '', // 联动的组件 id
    //             label: '', // 联动的组件名称
    //             event: '', // 监听事件
    //             style: [{ key: '', value: '' }], // 监听的事件触发时，需要改变的属性
    //         },
    //     ],
    // },
}

// 编辑器左侧组件列表
const list = [
    {
        component: 'StyleButton',
        label: '风格按钮',
        propValue: '',
        script:"",
        activeScript:"",
        icon: 'button',
        clickColor: 'rgba(255,255,255,1)',
        clickState: false,
        isLock: false,
        collapseName: '',
        onClickBgColor: 'rgba(255, 255, 255, 0)',
        clickPropValue: '',
        pageIndex: '',
        typeStyle: '0',
        websiteUrl: '',
        statusValue:0,
        activeText:"",
        style: {
            width: 90,
            height: 90,
            borderWidth: 1,
            borderColor: 'rgba(255, 255, 255, 0)',
            borderRadius: 0,
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 0,
            letterSpacing: 0,
            textAlign: '',
            color: 'rgba(255,255,255,1)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            backgroundImgId: initModelData[1].id,
            backgroundImgsId: initModelData[3].id,
            backgroundImg: initModelData[1].src,
            backgroundImgs: initModelData[3].src,
            bgImgWidth: 100,
            bgImgHeight: 100,
            vibrate: false,
            activeBgColor:'',
            activeColor:'',
            activeBgImg:'',
        },
        cmd: {
            ip: '',
            port: '',
            outagreement: 0,
            cmdList: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
            cmdListNotActive: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
        },
    },
    {
        component: 'StyleButton',
        label: '风格按钮',
        propValue: '',
        icon: 'button',
        clickColor: 'rgba(255,255,255,1)',
        clickState: false,
        isLock: false,
        collapseName: '',
        onClickBgColor: 'rgba(255, 255, 255, 0)',
        clickPropValue: '',
        pageIndex: '',
        typeStyle: '0',
        websiteUrl: '',
        style: {
            width: 90,
            height: 90,
            borderWidth: 1,
            borderColor: 'rgba(255, 255, 255, 0)',
            borderRadius: 0,
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 0,
            letterSpacing: 0,
            textAlign: '',
            color: 'rgba(255,255,255,1)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            backgroundImgId: initModelData[2].id,
            backgroundImgsId: initModelData[3].id,
            backgroundImg: initModelData[2].src,
            backgroundImgs: initModelData[3].src,
            bgImgWidth: 100,
            bgImgHeight: 100,
            vibrate: false,
        },
        cmd: {
            ip: '',
            port: '',
            outagreement: 0,
            cmdList: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
            cmdListNotActive: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
        },
    },
    {
        component: 'StyleButton',
        label: '风格按钮',
        propValue: '',
        icon: 'button',
        clickColor: 'rgba(255,255,255,1)',
        clickState: false,
        isLock: false,
        collapseName: '',
        onClickBgColor: 'rgba(255, 255, 255, 0)',
        clickPropValue: '',
        pageIndex: '',
        typeStyle: '0',
        websiteUrl: '',
        style: {
            width: 100,
            height: 40,
            borderWidth: 1,
            borderColor: 'rgba(255, 255, 255, 0)',
            borderRadius: 0,
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 0,
            letterSpacing: 0,
            textAlign: '',
            color: 'rgba(255,255,255,1)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            backgroundImgId: initModelData[4].id,
            backgroundImgsId: initModelData[5].id,
            backgroundImg: initModelData[4].src,
            backgroundImgs: initModelData[5].src,
            bgImgWidth: 100,
            bgImgHeight: 100,
            vibrate: false,
        },
        cmd: {
            ip: '',
            port: '',
            outagreement: 0,
            cmdList: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
            cmdListNotActive: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
        },
    },
    {
        component: 'StyleButton',
        label: '风格按钮',
        propValue: '',
        icon: 'button',
        clickColor: 'rgba(255,255,255,1)',
        clickState: false,
        isLock: false,
        collapseName: '',
        onClickBgColor: 'rgba(255, 255, 255, 0)',
        clickPropValue: '',
        pageIndex: '',
        typeStyle: '0',
        websiteUrl: '',
        style: {
            width: 100,
            height: 40,
            borderWidth: 1,
            borderColor: 'rgba(255, 255, 255, 0)',
            borderRadius: 0,
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 0,
            letterSpacing: 0,
            textAlign: '',
            color: 'rgba(255,255,255,1)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            backgroundImgId: initModelData[6].id,
            backgroundImgsId: initModelData[7].id,
            backgroundImg: initModelData[6].src,
            backgroundImgs: initModelData[7].src,
            bgImgWidth: 100,
            bgImgHeight: 100,
            vibrate: false,
        },
        cmd: {
            ip: '',
            port: '',
            outagreement: 0,
            cmdList: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
            cmdListNotActive: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
        },
    },
    {
        component: 'StyleButton',
        label: '风格按钮',
        propValue: '',
        icon: 'button',
        clickColor: 'rgba(255,255,255,1)',
        clickState: false,
        isLock: false,
        collapseName: '',
        onClickBgColor: 'rgba(255, 255, 255, 0)',
        clickPropValue: '',
        pageIndex: '',
        typeStyle: '0',
        websiteUrl: '',
        style: {
            width: 100,
            height: 40,
            borderWidth: 1,
            borderColor: 'rgba(255, 255, 255, 0)',
            borderRadius: 0,
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 0,
            letterSpacing: 0,
            textAlign: '',
            color: 'rgba(255,255,255,1)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            backgroundImgId: initModelData[8].id,
            backgroundImgsId: initModelData[9].id,
            backgroundImg: initModelData[8].src,
            backgroundImgs: initModelData[9].src,
            bgImgWidth: 100,
            bgImgHeight: 100,
            vibrate: false,
        },
        cmd: {
            ip: '',
            port: '',
            outagreement: 0,
            cmdList: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
            cmdListNotActive: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
        },
    },
    {
        component: 'StyleButton',
        label: '风格按钮',
        propValue: '科技风按钮',
        icon: 'button',
        clickColor: 'rgba(255,255,255,1)',
        clickState: false,
        isLock: false,
        collapseName: '',
        onClickBgColor: 'rgba(255,255,255,1)',
        clickPropValue: '科技风按钮',
        pageIndex: '',
        typeStyle: '0',
        websiteUrl: '',
        style: {
            width: 100,
            height: 40,
            borderWidth: 1,
            borderColor: 'rgba(255, 255, 255, 0)',
            borderRadius: 0,
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 0,
            letterSpacing: 0,
            textAlign: '',
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            backgroundImgId: initModelData[10].id,
            backgroundImgsId: initModelData[11].id,
            backgroundImg: initModelData[10].src,
            backgroundImgs: initModelData[11].src,
            bgImgWidth: 100,
            bgImgHeight: 100,
            vibrate: false,
        },
        cmd: {
            ip: '',
            port: '',
            outagreement: 0,
            cmdList: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
            cmdListNotActive: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
        },
    },
    // {
    //     component: 'VButton',
    //     label: '开关按钮',
    //     propValue: '',
    //     icon: 'button',
    //     clickColor:'rgba(0,0,0,1)',
    //     clickState:false,
    //     isLock:false,
    //     collapseName:'',
    //     onClickBgColor:'rgba(255, 255, 255, 0)',
    //     clickPropValue:'',
    //     pageIndex:'',
    //     style: {
    //         width: 90,
    //         height: 90,
    //         borderWidth: 1,
    //         borderColor: 'rgba(255, 255, 255, 0)',
    //         borderRadius: 0,
    //         fontSize: 12,
    //         fontWeight: 400,
    //         lineHeight: 0,
    //         letterSpacing: 0,
    //         textAlign: '',
    //         color: 'rgba(0, 0, 0, 1)',
    //         backgroundColor: 'rgba(255, 255, 255, 0)',
    //         backgroundImgId: initModelData[1].id,
    //         backgroundImgsId: initModelData[3].id,
    //         backgroundImg: initModelData[1].src,
    //         backgroundImgs: initModelData[3].src,
    //         bgImgWidth:100,
    //         bgImgHeight:100,
    //         vibrate:false,
    //     },
    //     cmd: {
    //         ip: '',
    //         port: '',
    //         outagreement:0,
    //         cmdList: [
    //             {
    //                 cmd: '',
    //                 passageway: "",
    //                 agreement: "",
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //         ],
    //         cmdListNotActive: [
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //         ]
    //     },
    // },
    // {
    //     component: 'VFastButton',
    //     label: '瞬触按钮',
    //     propValue: '',
    //     icon: 'button',
    //     clickColor:'rgba(0,0,0,1)',
    //     onClickBgColor:'rgba(0,0,0,0)',
    //     clickPropValue:'',
    //     pageIndex:'',
    //     cmdConnect:'',
    //     clickState:false,
    //     style: {
    //         width: 90,
    //         height: 90,
    //         borderWidth: 1,
    //         borderColor: 'rgba(255, 255, 255, 0)',
    //         borderRadius: 0,
    //         fontSize: 12,
    //         fontWeight: 400,
    //         lineHeight: 0,
    //         letterSpacing: 0,
    //         textAlign: '',
    //         color: 'rgba(0, 0, 0, 1)',
    //         backgroundColor: 'rgba(255, 255, 255, 0)',
    //         backgroundImgId: initModelData[1].id,
    //         backgroundImgsId: initModelData[3].id,
    //         backgroundImg: initModelData[1].src,
    //         backgroundImgs: initModelData[3].src,
    //         delay:300,
    //         bgImgWidth:100,
    //         bgImgHeight:100,
    //         vibrate:false,
    //     },
    //     cmd: {
    //         ip: '',
    //         port: '',
    //         outagreement:'',
    //         cmdList: [
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             },
    //         ],

    //     },
    // },
    // {
    //     component: 'VWebViewButton',
    //     label: '页面按钮',
    //     propValue: '页面按钮',
    //     icon: 'button',
    //     clickState:false,
    //     onClickBgColor:"rgba(255, 255, 255, 1)",
    //     clickColor:"rgba(0, 0, 0, 1)",
    //     style: {
    //         width: 100,
    //         height: 34,
    //         borderWidth: 1,
    //         borderColor: 'rgba(255, 255, 255, 1)',
    //         borderRadius: 0,
    //         fontSize: '12',
    //         fontWeight: 400,
    //         lineHeight: 0,
    //         letterSpacing: 0,
    //         textAlign: '',
    //         color: 'rgba(0, 0, 0, 1)',
    //         backgroundColor: 'rgba(255, 255, 255, 1)',
    //         backgroundImg: '',
    //         backgroundImgs: '',
    //         bgImgWidth:100,
    //         bgImgHeight:100,
    //         vibrate:false,
    //         backgroundImgsId:"",
    //         backgroundImgId:""
    //     },
    //    value:{
    //       type:1,
    //       url:'',
    //       loadJSContent:''
    //    }
    // },
    // {
    //     component: 'CtrlVButton',
    //     label: '按压按钮',
    //     propValue: '按压按钮',
    //     icon: 'button',
    //     clickColor:'rgba(0,0,0,1)',
    //     onClickBgColor:'rgba(0,0,0,1)',
    //     clickPropValue:'',
    //     clickState:false,
    //     style: {
    //         width: 100,
    //         height: 34,
    //         borderWidth: 1,
    //         borderColor: 'rgba(255, 255, 255, 1)',
    //         borderRadius: 0,
    //         fontSize: 12,
    //         fontWeight: 400,
    //         lineHeight: 0,
    //         letterSpacing: 0,
    //         textAlign: '',
    //         color: 'rgba(0, 0, 0, 1)',
    //         backgroundColor: 'rgba(255, 255, 255, 1)',
    //         backgroundImg: '',
    //         backgroundImgs: '',
    //         delay:300,
    //         bgImgWidth:100,
    //         bgImgHeight:100,
    //         vibrate:false,
    //         backgroundImgId:'',
    //         backgroundImgsId:''
    //     },
    //     cmd: {
    //         ip: '',
    //         port: '',
    //         outagreement:'',
    //         cmdList: [
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             }
    //         ],
    //         cmdListNotActive: [
    //             {
    //                 cmd: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 delay: 0,
    //                 bps: '',
    //             }
    //         ]
    //     },
    // },
    {
        component: 'VFastButtons',
        label: '瞬触按钮(多设备)',
        propValue: '瞬触按钮(多设备)',
        icon: 'button',
        style: {
            width: 120,
            height: 40,
            borderWidth: 1,
            borderColor: 'rgba(255, 255, 255, 0)',
            borderRadius: 0,
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 0,
            letterSpacing: 0,
            textAlign: '',
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            backgroundImgId: initModelData[10].id,
            backgroundImgsId: initModelData[11].id,
            backgroundImg: initModelData[10].src,
            backgroundImgs: initModelData[11].src,
            bgImgWidth: 100,
            bgImgHeight: 100,
            vibrate: false,
        },
        cmd: {
            cmdList: [
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''

                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
                {
                    ip: '',
                    port: '',
                    outagreement:0,
                    cmd: '',
                    delay: 0,
                    passageway: '',
                    agreement: '',
                    bps:''
                },
            ],
        },
    },
    // {
    //     component: 'VSelect',
    //     label: '下拉框',
    //     propValue: '下拉框',
    //     icon: 'zhixian',
    //     style: {
    //         width: 200,
    //         height: 28,
    //         fontSize: 20,
    //         fontWeight: 400,
    //         backgroundColor: '#fff',
    //         letterSpacing: 0,
    //         textAlign: '',
    //         color: '#000',
    //         borderWidth: 1,
    //         borderColor: '#ccc',
    //         borderRadius: '0',
    //     },
    //     data: [],
    // },
    // {
    //     component: 'VButtons',
    //     label: '多设备按钮',
    //     propValue: '多设备按钮',
    //     icon: 'button',
    //     style: {
    //         width: 100,
    //         height: 34,
    //         borderWidth: 1,
    //         borderColor: '#fff',
    //         borderRadius: '0',
    //         fontSize: '12',
    //         fontWeight: 400,
    //         lineHeight: '',
    //         letterSpacing: 0,
    //         textAlign: '',
    //         color: '#000',
    //         backgroundColor: '#fff',
    //         backgroundImg: '',
    //         backgroundImgs: '',
    //         bgImgWidth:100,
    //         bgImgHeight:100,
    //         vibrate:false,
    //     },
    //     cmd: {

    //         cmdList: [
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''

    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:'',
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: 0,
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: 0,
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: 0,
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: 0,
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: 0,
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: 0,
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: 0,
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: 0,
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: 0,
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: 0,
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //         ],
    //         cmdListNotActive:  [
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''

    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: 0,
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //             {
    //                 ip: '',
    //                 port: '',
    //                 outagreement:0,
    //                 cmd: '',
    //                 delay: '',
    //                 passageway: '',
    //                 agreement: '',
    //                 bps:''
    //             },
    //         ],
    //     },
    // },
    {
        component: 'Vmatrix',
        label: '矩阵',
        propValue: '矩阵',
        icon: 'button',
        clickPropValue: '',
        clickState: false,
        // activeState:false,
        onClickBgColor: 'rgba(255, 255, 255, 0)',
        clickColor: '',
        cmds: '',
        style: {
            width: 110,
            height: 50,
            borderWidth: 1,
            borderColor: 'rgba(204, 204, 204, 0)',
            borderRadius: 0,
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 0,
            letterSpacing: 0,
            textAlign: '',
            color: 'rgba(0, 0, 0, 1)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            bgImgWidth: 100,
            bgImgHeight: 100,
            backgroundImgId: initModelData[12].id,
            backgroundImgsId: initModelData[13].id,
            backgroundImg: initModelData[12].src,
            backgroundImgs: initModelData[13].src,
        },
        cmd: {
            ip: '',
            port: '',
            outagreement: 0,
            cmdList: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
            cmdListNotActive: [
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
                {
                    cmd: '',
                    passageway: '',
                    agreement: '',
                    delay: 0,
                    bps: '',
                },
            ],
        },
    },
    // {
    //     component: 'VText',
    //     label: '文字',
    //     propValue: '双击编辑文字',
    //     icon: 'wenben',
    //     style: {
    //         width: 290,
    //         height: 28,
    //         fontSize: 20,
    //         fontWeight: 400,
    //         // BgImg: '',
    //        // backgroundColor: 'transparent',
    //       //  borderRadius: '0',
    //         lineHeight: 0,
    //         letterSpacing: 0,
    //         textAlign: '',
    //         color: 'rgba(255, 255, 255, 1)',
    //     },
    //     value: '',
    //     timeType:false
    // },
    {
        component: 'Vrange',
        label: '音量滑块',
        propValue: '',
        icon: 'zhixian',
        style: {
            width: 200,
            height: 25,
            activeBgColor: 'rgba(0,0,0,1)',
            spotWidth: 50,
            spotHeight: 50,
            maxValue:100,
            nowValue:0,
            minValue:0,
            spotBgImgId: initModelData[14].id,
            spotBgImg:initModelData[14].src,
        },
        cmd: {
            // ip: '',
            // port: '',
            // bps:'',
            // agreement: 3,
            // outagreement:'',
            // passageway: '',
            // cmdType:'',
            // thoroughfare:'',
            // thoroughfareType:'',
            // pushrod:'',
            // pageNumber:'',
            script:'//定义一个nowValue变量\n//调用内部方法将10进制转成16进制\n//并将转换的值赋给nowValue\n//定义一个返回结果对象\n//1.cmd 最终发送出去的值\n//2.ip 接收数据的ip\n//3.协议端口\n//4.协议0 udp 1 tcp\n//this.decimalToHex 10转16进制\n//value 滑块当前值\n//this.print 在下方框输出内容\nvar nowValue;\nnowValue=this.decimalToHex(value);\nvar resultData ={\n"cmd":"0a"+nowValue,\n"ip":"192.168.2.108",\n"port":8080,\n"protocol":0\n}\nthis.print(resultData)'
    },
},
    // {
    //     component: 'VIframe',
    //     label: '嵌套网页',
    //     propValue: '嵌套网页',
    //     icon: 'zhixian',
    //     value: 'http://alishan-audio.cn',
    //     style: {
    //         width: 300,
    //         height: 300,
    //         borderRadius: 30,
    //     },
    // },
    {
        component: 'Vplayer',
        label: '播放器',
        propValue: '播放器',
        icon: 'zhixian',
        value: '',
        style: {
            width: 300,
            height: 300,
            borderRadius: 30,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            bgImg: initModelData[12].src,
        },
    },
    // {
    //     component: 'Picture',
    //     label: '图片',
    //     icon: 'tupian',
    //     propValue: {
    //         url: require('@/assets/title.jpg'),
    //         flip: {
    //             horizontal: false,
    //             vertical: false,
    //         },
    //     },
    //     style: {
    //         width: 300,
    //         height: 200,
    //         borderRadius: '',
    //     },
    // },
    // {
    //     component: 'RectShape',
    //     label: '矩形',
    //     propValue: '&nbsp;',
    //     icon: 'juxing',
    //     style: {
    //         width: 200,
    //         height: 200,
    //         fontSize: '',
    //         fontWeight: 400,
    //         lineHeight: '',
    //         letterSpacing: 0,
    //         textAlign: 'center',
    //         color: '',
    //         borderColor: '#000',
    //         borderWidth: 1,
    //         backgroundColor: '',
    //         borderStyle: 'solid',
    //         borderRadius: '',
    //         verticalAlign: 'middle',
    //     },
    // },
    // {
    //     component: 'LineShape',
    //     label: '直线',
    //     propValue: '',
    //     icon: 'zhixian',
    //     style: {
    //         width: 200,
    //         height: 2,
    //         backgroundColor: '#000',
    //     },
    // },
    // {
    //     component: 'CircleShape',
    //     label: '圆形',
    //     propValue: '&nbsp;',
    //     icon: '24gl-circle',
    //     style: {
    //         width: 200,
    //         height: 200,
    //         fontSize: '',
    //         fontWeight: 400,
    //         lineHeight: '',
    //         letterSpacing: 0,
    //         textAlign: 'center',
    //         color: '',
    //         borderColor: '#000',
    //         borderWidth: 1,
    //         backgroundColor: '',
    //         borderStyle: 'solid',
    //         borderRadius: '',
    //         verticalAlign: 'middle',
    //     },
    // },
    // {
    //     component: 'SVGStar',
    //     label: '星形',
    //     icon: 'kongwujiaoxing',
    //     propValue: '',
    //     style: {
    //         width: 80,
    //         height: 80,
    //         fontSize: '',
    //         fontWeight: 400,
    //         lineHeight: '',
    //         letterSpacing: 0,
    //         textAlign: 'center',
    //         color: '',
    //         borderColor: '#000',
    //         backgroundColor: 'rgba(255, 255, 255, 1)',
    //     },
    // },
    // {
    //     component: 'SVGTriangle',
    //     label: '三角形',
    //     icon: 'xingzhuang-sanjiaoxing',
    //     propValue: '',
    //     style: {
    //         width: 80,
    //         height: 80,
    //         fontSize: '',
    //         fontWeight: 400,
    //         lineHeight: '',
    //         letterSpacing: 0,
    //         textAlign: 'center',
    //         color: '',
    //         borderColor: '#000',
    //         backgroundColor: 'rgba(255, 255, 255, 1)',
    //     },
    // },
    // {
    //     component: 'VTable',
    //     label: '表格',
    //     icon: 'biaoge',
    //     propValue: {
    //         data: [
    //             ['表头1', '表头2', '表头3'],
    //             ['内容1', '内容2', '内容3'],
    //         ],
    //         stripe: true,
    //         thBold: true,
    //     },
    //     request: {
    //         method: 'GET',
    //         data: [],
    //         url: '',
    //         series: false,
    //         time: 1000,
    //         paramType: '', // string object array
    //         requestCount: 0, // 请求次数限制，0 为无限
    //     },
    //     style: {
    //         width: 600,
    //         height: 200,
    //         fontSize: '',
    //         fontWeight: 400,
    //         textAlign: 'center',
    //         color: '',
    //         backgroundColor: 'rgba(255, 255, 255, 1)',
    //     },
    // },
]

for (let i = 0, len = list.length; i < len; i++) {
    const item = list[i]
    item.style = { ...commonStyle, ...item.style }
    list[i] = { ...commonAttr, ...item }
}

export default list
