import axios from 'axios'

let protocol = window.location.protocol // 协议
let host = window.location.hostname // 主机
let port = 81 // 主机
let reg = /^localhost+/
axios.defaults.baseURL = 'https://api.iavcms.com/app/'
// axios.defaults.baseURL = 'http://43.138.170.120/app/'
// if (reg.test(host)) {
//     // 若本地项目调试使用
//     axios.defaults.baseURL = 'http://localhost/app/'
// } else {
//     // 动态请求地址             协议               主机
//     axios.defaults.baseURL = protocol + '//' + host + ':' + port + '/app/'
// }

// 创建axios实例
let service = axios.create({
    timeout: 60000,
})
export default {
    service,

    // get请求
    get(url, data) {
        return service({
            url,
            method: 'get',
            params: data,
        })
    },

    post(url, data) {
        return service({
            url,
            method: 'post',
            data,
        })
    },

    put(url, data) {
        return service({
            url,
            method: 'put',
            data,
        })
    },

    delete(url, data) {
        return service({
            url,
            method: 'delete',
            data,
        })
    },
}
