import { sin, cos, toPercent } from '@/utils/translate'

export function getShapeStyle(style, globalStyleV) {
    if (style == undefined) {
        return ''
    }

    let globalStyle = 'px'
    if (globalStyleV == '%') {
        globalStyle = '%'
    }

    const result = {};
    ['width', 'height', 'top', 'left', 'rotate'].forEach(attr => {
        if (attr != 'rotate') {
            result[attr] = style[attr] + 'px'
        } else {
            result.transform = 'rotate(' + style[attr] + 'deg)'
        }

        if (attr == 'width' || attr == 'height' || attr == 'top' || attr == 'left') {
            result[attr] = style[attr] + globalStyle
        }
    })

    return result
}

const needUnit = [
    'fontSize',
    'width',
    'height',
    'top',
    'left',
    'borderWidth',
    'letterSpacing',
    'borderRadius',
]

export function getSVGStyle(style, filter = []) {
    const result = {};
    [
        'opacity',
        'width',
        'height',
        'top',
        'left',
        'rotate',
        'fontSize',
        'fontWeight',
        'lineHeight',
        'letterSpacing',
        'textAlign',
        'color',
    ].forEach(key => {
        if (!filter.includes(key)) {
            if (key != 'rotate') {
                if (style[key] !== '') {
                    result[key] = style[key]
                    if (needUnit.includes(key)) {
                        result[key] += 'px'
                    }
                }
            } else {
                result.transform = key + '(' + style[key] + 'deg)'
            }
        }
    })

    return result
}

export function getStyle(style, filter = []) {
    const result = {}

    Object.keys(style).forEach(key => {
        if (!filter.includes(key)) {
            if(key == 'activeBgImg'){

                result['background-image'] = 'url(' + style[key] + ')'
            }else if(key == 'activeColor'){
                result['color'] = style[key]
            }else if(key == 'activeBgColor'){
                result['backgroundColor'] = style[key]
            }
            else if (key != 'rotate' && key != 'backgroundImg' && key != 'Bgimg') {
                if (style[key] !== '') {
                    result[key] = style[key]

                    if (needUnit.includes(key)) {
                        result[key] += 'px'
                    }
                }

            }
             else if (key == 'backgroundImg' || key == 'Bgimg') {
                result['background-image'] = 'url(' + style[key] + ')'
                result['background-repeat'] = 'no-repeat'
                // result['background-size'] = 'contain'
                result['background-size'] = style.bgImgWidth + '% ' + style.bgImgHeight + '% !important'
            } else if (key == 'rotate') {
                result.transform = key + '(' + style[key] + 'deg)'
            } else if (key == 'bgImgWidth') {

            }
        }
    })

    return result
}

// 获取一个组件旋转 rotate 后的样式
export function getComponentRotatedStyle(style) {
    style = { ...style }
    if (style.rotate != 0) {
        const newWidth = style.width * cos(style.rotate) + style.height * sin(style.rotate)
        const diffX = (style.width - newWidth) / 2 // 旋转后范围变小是正值，变大是负值
        style.left += diffX
        style.right = style.left + newWidth

        const newHeight = style.height * cos(style.rotate) + style.width * sin(style.rotate)
        const diffY = (newHeight - style.height) / 2 // 始终是正
        style.top -= diffY
        style.bottom = style.top + newHeight

        style.width = newWidth
        style.height = newHeight
    } else {
        style.bottom = style.top + style.height
        style.right = style.left + style.width
    }

    return style
}

const filterKeys = ['width', 'height', 'scale']
export function getCanvasStyle(canvasStyleData) {
    const result = {}
    if (canvasStyleData == null || canvasStyleData == undefined) {
        return result
    }

    Object.keys(canvasStyleData).filter(key => !filterKeys.includes(key)).forEach(key => {
        result[key] = canvasStyleData[key]

        if (key === 'fontSize') {
            result[key] += 'px'
        }
    })

    // if (canvasStyleData.backgroundImg != '') {
    //     result['background-image'] = 'url("' + canvasStyleData.backgroundImg + '") !important'
    // }

    if (canvasStyleData.backgroundColor == undefined || canvasStyleData.backgroundColor == null) {
        // canvasStyleData.backgroundColor = 'rgba(38, 42, 48, 1)'
        // result['background-color'] = 'rgba(38, 42, 48, 1)'
    } else {
        result['background-color'] = canvasStyleData.backgroundColor
    }
    delete result.backgroundImg
    console.log(result)

    return result
}

export function createGroupStyle(groupComponent) {
    const parentStyle = groupComponent.style
    groupComponent.propValue.forEach(component => {
        // component.groupStyle 的 top left 是相对于 group 组件的位置
        // 如果已存在 component.groupStyle，说明已经计算过一次了。不需要再次计算
        if (!Object.keys(component.groupStyle).length) {
            const style = { ...component.style }
            if (component.component.startsWith('SVG')) {
                component.groupStyle = getSVGStyle(style)
            } else {
                component.groupStyle = getStyle(style)
            }

            component.groupStyle.left = toPercent((style.left - parentStyle.left) / parentStyle.width)
            component.groupStyle.top = toPercent((style.top - parentStyle.top) / parentStyle.height)
            component.groupStyle.width = toPercent(style.width / parentStyle.width)
            component.groupStyle.height = toPercent(style.height / parentStyle.height)
        }
    })
}
